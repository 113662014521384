import {
  CalendarDate,
  CalendarDateTime,
  ZonedDateTime,
  getLocalTimeZone,
  now,
} from '@internationalized/date'

// TODO: use value from config
let locale = 'en-GB'

export const dayFormat = new Intl.DateTimeFormat(locale, {
  day: 'numeric',
})

export const weekdayFormat = new Intl.DateTimeFormat(locale, {
  weekday: 'short',
})

export const monthFormat = new Intl.DateTimeFormat(locale, {
  month: 'short',
})

export const timeFormat = new Intl.DateTimeFormat(locale, {
  hour: '2-digit',
  minute: '2-digit',
})

export const dayMonthShortFormat = new Intl.DateTimeFormat(locale, {
  day: 'numeric',
  month: 'short',
})

export const dayMonthYearFOrmat = new Intl.DateTimeFormat(locale, {
  day: 'numeric',
  month: 'short',
  year: '2-digit',
})

export const fullFormat = new Intl.DateTimeFormat(locale, {
  dateStyle: 'short',
  timeStyle: 'short',
})

export const timeWithSecondsFormat = new Intl.DateTimeFormat(locale, {
  timeStyle: 'medium',
})

export const yearFormat = new Intl.DateTimeFormat(locale, {
  year: 'numeric',
})

export function getDateHoursAgo(hours: number): Date {
  const hoursAgo = new Date()
  hoursAgo.setHours(hoursAgo.getHours() - hours)

  return hoursAgo
}

export function getDateMonthsAgo(months: number): Date {
  const monthsAgo = new Date()
  monthsAgo.setMonth(monthsAgo.getMonth() - months)

  return monthsAgo
}

export function isPast(
  date: CalendarDate | CalendarDateTime | ZonedDateTime,
): boolean {
  let todayDate = now(getLocalTimeZone())
  return todayDate.compare(date) > 0
}

export function isMoreThanYearsAgo(
  date: CalendarDate | CalendarDateTime | ZonedDateTime,
  years: number,
): boolean {
  let todayDate = now(getLocalTimeZone())
  let yearsAgo = todayDate.subtract({ years })
  return yearsAgo < date
}

export function isMoreThanMinutesAgo(
  date: CalendarDate | CalendarDateTime | ZonedDateTime,
  minutes: number,
): boolean {
  let todayDate = now(getLocalTimeZone())
  let minutesAgo = todayDate.subtract({ minutes })
  return minutesAgo < date
}

export function isMoreThanSecondsAgo(
  date: CalendarDate | CalendarDateTime | ZonedDateTime,
  seconds: number,
): boolean {
  let todayDate = now(getLocalTimeZone())
  let timeAgo = todayDate.subtract({ seconds })
  return timeAgo < date
}

export function timeAgo(date: Date) {
  const now = new Date()
  const timeDifference = now.getTime() - date.getTime()

  const seconds = Math.floor(timeDifference / 1000)
  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)

  if (days > 0) return `${days}d ago`
  if (hours > 0) return `${hours}h ago`
  if (minutes > 0) return `${minutes}m ago`
  if (seconds > 0) return `${seconds}s ago`
  return 'just now'
}
